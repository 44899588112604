.blog {
    font-family: "Noto Sans JP, sans-serif";
    a {
        text-decoration: none;
        color: #4184F3;
    }

    .header {
        #title {
            text-align: center;

            a {
                text-decoration: none;
                color: #333;
            }
        }

        .navigationBar {
            text-align: center;

            .group {
                padding: 0;
                margin: 0;
            }

            .item {
                list-style: none;
                display: inline-block;
                width: 18%;
                min-width: 90px;

                a {
                    text-decoration: none;
                    color: #333;
                }
            }
        }
    }

    .container {
        margin: 0 auto;
        margin-top: 100px;

        @media screen and (min-width: 480px) {
            width: 100%;
        }

        @media screen and (min-width: 768px) {
            width: 80%;
            max-width: 900px;
        }


        table {
            border-collapse: collapse;
            border-spacing: 0;
            width: 100%;
            margin: 10px;
        }
        
        table tr {
          border-bottom: solid 1px #ccc;
          cursor: pointer;
        }
        
        table th, table td {
          width: 25%;
          padding: 15px 0;
        }

        table th {
            text-align: center;
        }

        table td {
            text-align: left;
        }
    }

    .section {
        .posts {
            .postCard {
                list-style: none;
                padding-top: 50px;

                a {
                    text-decoration: none;

                    .createdDate {
                        color: #555;
                    }

                    .title {
                        color: #333;
                        padding: 0;
                        margin: 0;
                    }

                    .description {
                        color: #777;
                    }
                }
            }
        }
    }

    .page, .static-page {
        img {
            width: 100%;
            box-shadow: 5px 5px 10px rgba(0,0,0,0.6);
        }

        pre {
            padding: 1rem;
            overflow: auto
        }

        h2 {
            margin-top: 50px;
            font-size: 32px;
        }

        h3 {
            font-size: 24px;
            margin-top: 50px;
        }

        p {
            margin: 30px 0;
            margin-left: 10px;
        }

        p, ul {
            color: #444;
            font-size: 16px;
            line-height: 20px;
        }

        p > code {
            color: #00995e;
            font-weight: bold;
            margin: 0 5px;
        }

        .tags {
            margin: 50px 0;

            a {
                margin: 0 2px;
            }
        }
    }

    .footer {
        margin-top: 100px;
        height: 50px;

        .container {
            width: 100%;
            text-align: center;
        }

        a {
            text-decoration: none;
            color: #333;
        }
    }
}
